import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const MyPurchase = () => {
  const user = useSelector((state) => state.user);
  const [myPurchase, setMyPurchase] = useState([]);

  const getMyPurchase = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/transactions/myPurchase/${user.id}`,
        {
          user,
          auth: localStorage.getItem("AdminToken"),
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      )
      .then(function (response) {
        console.log("Purchase History : ", response.data);
        setMyPurchase(response.data);
        toast.success(`Successfully got your Purchase History!!`, {
          autoClose: 3000,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.status);
        if (error.response.status) {
          toast.error(`Please Login Again!!`, {
            autoClose: 3000,
          });
        } else {
          toast.error(`Sorry, facing some issues with your request!!`, {
            autoClose: 3000,
          });
        }
      })
      .finally(function () {
        // always executed
      });
  };

  useEffect(() => {
    getMyPurchase();
  }, []);
  // getMyPurchase();

  return (
    <>
      <Navbar />
      <section className="flex flex-col lg:flex-row flex-wrap gap-5 gap-x-1 m-1 mt-5 mb-5 max-w-screen p-0 sm:px-2">
        <div className="flex flex-col gap-4 m-1 sm:m-5 items-center justify-center w-full">
          <div className="relative grid grid-cols-1 md:grid-cols-2 justify-items-center gap-2 gap-y-4 grid-flow-row p-2 sm:p-5 py-5 min-h-min flex-2 shadow-xl bg-white rounded-lg w-full min-h-96">
            <div className="text-3xl font-semibold text-center py-5 mt-3 text-gray-900 w-full col-span-1 md:col-span-2">
              {(myPurchase.length)?"":"No "} Purchase History
            </div>
            {myPurchase.map((purchase, index) => (
              <div
                key={index}
                class="group p-2.5 relative w-10/12 shadow-xl bg-white border border-gray-200 rounded-xl overflow-hidden dark:bg-gray-800 dark:border-gray-700 h-min"
              >
                <div class="p-5">
                  <h5 class="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white tracking-wider">
                    Receipt{" "}
                    <span className="text-gray-300 dark:text-slate-300">
                      #{index + 1}
                    </span>
                  </h5>
                  <hr className="mt-3 mb-8 border-2 border-dashed" />
                  <h5 class="my-2 text-xl lg:text-xl font-bold text-gray-900 dark:text-white">
                    Paid on{" "}
                    <span className="text-gray-300 dark:text-slate-300">
                      {new Date(
                        purchase.transactionCreatedAt * 1000
                      ).toLocaleString()}
                    </span>
                  </h5>
                  <h5 class="my-2 text-xl lg:text-xl font-bold text-gray-900 dark:text-white">
                    Invoice no{" "}
                    <span className="text-gray-300 dark:text-slate-300">
                      {purchase.invoiceNo}{" "}
                    </span>
                  </h5>
                  <h5 class="my-2 text-xl lg:text-xl font-bold text-gray-900 dark:text-white">
                    Mode as{" "}
                    <span className="text-gray-300 dark:text-slate-300">
                      {purchase.studentMode ? "Student" : "Delegate"}{" "}
                    </span>
                  </h5>
                  <h5 class="my-2 text-xl lg:text-xl font-bold text-gray-900 dark:text-white">
                    Transaction no{" "}
                    <span className="text-gray-300 dark:text-slate-300">
                      {purchase.id}
                    </span>
                  </h5>
                  <h5 class="mt-6 mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-slate-300">
                    Itineraries
                  </h5>
                  <p class="mb-3 font-normal text-wrap text-gray-700 dark:text-gray-400">
                    <ol className="ml-4 space-y-1 text-gray-500 list-decimal dark:text-gray-400">
                      {purchase.cart.map((item, index) => (
                        <li key={index}>
                          {item[3]} x {item[1]} - {item[2].toFixed(2)}% off
                        </li>
                      ))}
                    </ol>
                  </p>
                  <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-slate-300">
                    People Included
                  </h5>
                  <p class="mb-3 font-normal text-wrap text-gray-700 dark:text-gray-400">
                    <ol className="ml-4 space-y-1 text-gray-500 list-decimal dark:text-gray-400">
                      {purchase.person.map((item, index) => (
                        <li key={index}>
                          <span class="font-semibold text-gray-300 dark:text-slate-300">
                            {item["name"]}
                          </span>{" "}
                          ({item["relation"]})<br /> {item["email"]}{" "}
                          {item["phone"]}
                          <br />
                          <span class="font-semibold text-gray-300 dark:text-slate-300">
                            Items:
                          </span>{" "}
                          {item["prodNameList"].join(", ")}
                        </li>
                      ))}
                    </ol>
                  </p>
                  <h5 class="my-4 text-2xl font-bold text-gray-900 dark:text-white">
                    {purchase.currency.toUpperCase()}{" "}
                    <span className="text-gray-300 dark:text-slate-300">
                      {purchase.amountTotal.toFixed(2)}{" "}
                      {purchase.currency.toLowerCase() !== "usd" ? (
                        <span className="text-base font-light">
                          at{" "}
                          <span class="font-semibold text-gray-300 dark:text-slate-300">
                            {purchase.fxRate}
                          </span>{" "}
                          Fx Rate (incl. 2% conversion markup)
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                  <a
                    href={purchase.url}
                    target="_blank"
                    className="mt-6 flex items-center justify-center px-2 py-2 font-medium text-center text-wrap text-white focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-lg dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 cursor-pointer w-full"
                  >
                    Request receipt PDF
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MyPurchase;
