import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { setUserAuth } from "../reducer/UserReducer";
import DOMPurify from "dompurify";

const Modal = () => {
  const user = useSelector((state) => state.user);
  const [authToken, setAuthToken] = useState(null);
  const [close, setClose] = useState(false);
  const dispatch = useDispatch();

  const auth = async () => {
    const password = DOMPurify.sanitize(
      document.querySelector("#adminPassword").value
    );

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/auth/adminAuth`,
        {
          user,
          password,
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      )
      .then(function (response) {
        const token = response.data.token;
        localStorage.setItem("AdminToken", token);
        setAuthToken(token);
        toast.success(`LoggedIn Successfully!!`, {
          autoClose: 3000,
        });
        dispatch(setUserAuth({ auth: token }));
        setTimeout(() => setClose(true), 1000);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.status);
        if (error.response.status) {
          toast.error(`Please Login Again!!`, {
            autoClose: 3000,
          });
        } else {
          toast.error(`Sorry, facing some issues with your request!!`, {
            autoClose: 3000,
          });
        }
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <div
      id="authentication-modal"
      tabindex="-1"
      aria-hidden="true"
      className={`overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex justify-center items-center backdrop-blur ${
        close ? "hidden" : ""
      }`}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {authToken === null ? (
                <>Again login for admin privileges.</>
              ) : (
                <>LoggedIn Successfully!!</>
              )}
            </h3>
            {/* <button
              onClick={()=>setClose(true)}
              type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="authentication-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button> */}
          </div>
          <div className="p-4 md:p-5">
            <div className="space-y-4" action="#">
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder={user.email}
                  value={user.email}
                  disabled
                  readonly
                />
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your password
                </label>
                <input
                  type="password"
                  name="password"
                  id="adminPassword"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                />
              </div>
              <div className="flex justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                      required
                    />
                  </div>
                  <label
                    for="remember"
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
                <a
                  href="https://panel.isabe.org/admin"
                  target="_blank"
                  className="text-sm text-blue-700 hover:underline dark:text-blue-500"
                >
                  Lost Password?
                </a>
              </div>
              <button
                onClick={auth}
                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Login to your account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
