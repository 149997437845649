import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import Footer from "./Footer";

const CancelPage = () => {
  return (
    <section className="h-screen flex flex-col justify-between items-stretch">
      <Navbar />
      <div className="flex flex-col lg:flex-row flex-wrap gap-5 m-5 h-96">
        <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center grid-flow-row gap-4 m-1 sm:m-5 p-2 sm:p-5 py-5 flex-grow lg:w-3/5 shadow-xl bg-white rounded-lg max-h-full ">
          <div className="text-3xl font-semibold text-center py-5 mt-3 text-red-500 w-full col-span-1 md:col-span-2">
            Cancel!!
          </div>
          <div className="text-xl font-normal text-center py-5 mt-3 text-gray-900 w-full col-span-1 md:col-span-2">
            Payment Canceled. In case the amount is deducted contact us from our contact us page.  
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default CancelPage;
