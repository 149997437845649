import './App.css';
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import { logPageView } from './analytics';
import { useEffect} from "react";
import Home from './components/Home';
import Success from './components/Success';
import CancelPage from './components/CancelPage';
import MyPurchase from './components/MyPurchase';

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function App() {


  return (
    <BrowserRouter>
      <RouteTracker />
      <Routes>
        <Route path="/:id/:role_id/:email/:full_name/:country_id/:phone" element={<Home/>}></Route>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/myPurchase" element={<MyPurchase/>}></Route>
        <Route path="/success" element={<Success/>}></Route>
        <Route path="/cancel" element={<CancelPage/>}></Route>
      </Routes>
    </BrowserRouter>    
  );
}

export default App;
