const Footer = () => {
  return (
    <footer className="bg-white dark:bg-slate-900 shadow-lg">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a
              href="https://isabe.org/"
              className="flex items-start flex-col max-w-lg"
            >
              <img
                src="../../../../../assets/img/logo.png"
                className="h-20 me-3 mb-2"
                alt="ISABE Logo"
              />
              <span className="self-center text-justify text-gray-500 dark:text-gray-400 font-medium">
                The International Society for Air Breathing Engines (ISABE) is
                an organization that was formed to further the free exchange, on
                an international level, of knowledge in the field of
                airbreathing propulsion for flight vehicles. ISABE has national
                representatives from more than 25 nations and holds events on
                six continents.
              </span>
            </a>
          </div>
          <div className="ms-2 grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 justify-items-start sm:justify-items-center">
            <div className="col-span-1">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Address
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <span className=" text-wrap">
                    150 West Market Street,<br/> Suite 800,<br/> Indianapolis, IN, 46204
                    USA
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-span-2">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact Info
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-2">
                  <a
                    href="stephane.burguburu@safrangroup.com"
                    className="hover:underline "
                  >
                    ISABE Vice President<br/>stephane.burguburu@safrangroup.com
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="rainer.walther@outlook.com"
                    className="hover:underline"
                  >
                    Administrative Secretary<br/>rainer.walther@outlook.com
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="Robert.F.Proctor@Rolls-Royce.com"
                    className="hover:underline"
                  >
                    Executive Secretary<br/>Robert.F.Proctor@Rolls-Royce.com
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="sneddeng@ukzn.ac.za"
                    className="hover:underline"
                  >
                    Treasurer<br/>sneddeng@ukzn.ac.za
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="sneddeng@ukzn.ac.za"
                    className="hover:underline"
                  >
                    Communications Officer<br/>isabe@cranfield.ac.uk
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Terms &amp; Conditions
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://isabe.org/" class="hover:underline">ISABE</a>. All Rights Reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
