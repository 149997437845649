import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem } from "../reducer/ShoppingCartReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Product = ({
  id,
  name,
  description,
  price,
  imageSrc,
  imageAlt,
  href,
  quantity,
  discount,
  category,
  discountMsg,
  limited,
  active,
  hide,
}) => {
  const productsList = useSelector((state) => state.shoppingCart);
  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  return (
    <div className="group relative max-w-sm shadow-xl bg-white border border-gray-200 rounded-xl overflow-hidden dark:bg-gray-800 dark:border-gray-700 h-auto sm:h-min">
      <a href={href} className="relative" target="_blank">
        <img
          className="rounded-t-lg h-64 w-auto cursor-pointer md:group-hover:blur-sm"
          src={imageSrc}
          alt={imageAlt}
        />
        <div className="text-center text-2xl font-bold tracking-tight dark:text-white w-max absolute transition-all top-0 translate-x-28 opacity-0 md:group-hover:opacity-100 md:group-hover:translate-y-32">
          Read More{" "}
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white mx-auto inline"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
      </a>
      <div className="p-5">
        <a href="#">
          <h5 className="mb-0.5 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {name.split(" ").slice(0, -1).join(" ")}
          </h5>
          <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-slate-300">
            {name.split(" ").pop()}
          </h5>
        </a>
        <p className="mb-3 font-normal text-wrap text-gray-700 dark:text-gray-400">
          {description}
        </p>

        <div className="flex flex-row flex-wrap items-center justify-between mt-5 gap-2">
          {discount ? (
            <span className="text-xl lg:text-2xl font-bold text-gray-900 dark:text-white">
              <span className="line-through decoration-red-500 mr-1">
                ${price}&nbsp;
              </span>
              {">"}${((price * (100 - discount)) / 100).toFixed(2)}
            </span>
          ) : (
            <span className="text-2xl font-bold text-gray-900 dark:text-white">
              ${price}
            </span>
          )}

          {productsList.map((product) => product.id).includes(id) ? (
            <button
              className="inline-flex items-center px-3 py-2 font-medium text-center text-wrap text-white focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 text-sm cursor-pointer"
              onClick={() => {
                dispatch(deleteItem({ id }));
                toast.success(`Removed ${name} form the cart.`, {
                  autoClose: 3000,
                });
              }}
            >
              Remove
              <svg
                className="ms-2 w-[20px] h-[20px] text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
            </button>
          ) : (quantity < 1 && limited) || (!active && user.role_id !== 5) ? (
            <button className="inline-flex items-center px-3 py-2 font-medium text-center text-wrap text-white focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 text-sm cursor-not-allowed">
              0 Left
              <svg
                className="ms-2 w-[20px] h-[20px] text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
          ) : (
            <button
              className="inline-flex items-center px-2 py-2 font-medium text-center text-wrap text-white focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 cursor-pointer"
              onClick={() => {
                dispatch(
                  addItem({
                    id,
                    name,
                    href,
                    description,
                    price,
                    imageSrc,
                    imageAlt,
                    quantity,
                    discount,
                    category,
                    active,
                    hide,
                  })
                );
                toast.success(`Added ${name} to the cart.`, {
                  autoClose: 3000,
                });
              }}
            >
              Add To Cart
              <svg
                className="ms-2 w-[20px] h-[20px] text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7h-1M8 7h-.688M13 5v4m-2-2h4"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      {(!active || hide) && user.role_id == 5 ? (
        <div className="absolute top-8 -right-16 text-center font-semibold text-base bg-red-700 border-red-800 w-64  px-4 py-2.5 text-white shadow-xl tracking-wider rotate-45">
          {!active && <> Inactive</>}
          {hide && <> Hidden</>} {limited && <><br/>{quantity} left</>}
        </div>
      ) : limited ? (
        <div className="absolute top-8 -right-14 text-center font-semibold text-base bg-gray-950 border-gray-800 w-56  px-12 py-2.5 text-white shadow-xl tracking-wider rotate-45">
          {(quantity > 1 ) && active? <>{quantity} Seats Left</> : <>Closed</>}
        </div>
      ) : discountMsg.length > 0 ? (
        <div className="absolute top-8 -right-14 text-center font-semibold text-base bg-gray-950 border-gray-800 w-56  px-12 py-2.5 text-white shadow-xl tracking-wider rotate-45">
          {discountMsg}
        </div>
      ) : discount > 0 ? (
        <div className="absolute top-8 -right-14 text-center font-semibold text-base bg-gray-950 border-gray-800 w-56  px-12 py-2.5 text-white shadow-xl tracking-wider rotate-45">
          Offer
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Product;
