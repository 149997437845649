import { useSelector, useDispatch } from "react-redux";
import { setStudentState } from "../reducer/StudentModeReducer";

const StudentBtn = () => {
  const studentMode = useSelector((state) => state.studentMode.mode);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-row flex-wrap gap-4 justify-around items-center p-3 sm:p-5 py-5 shadow-xl bg-white rounded-lg w-full h-auto">
      <div className="flex flex-col gap-0 h-auto items-start">
        <span className="text-2xl font-medium text-center py-1 text-gray-900">
          Are you a Full-Time Student ?
        </span>
        <span className="text-base text-gray-500 font-normal text-center py-0">
          <b>Note:</b> You may be asked to demonstrate your full-time student status.
        </span>
      </div>
      <div class="flex rounded-md shadow-sm" role="group">
        {studentMode ? (
          <>
            <button
              type="button"
              className="shadow-xl px-10 py-2 text-base font-medium text-white bg-green-600 border border-gray-900 rounded-s-lg hover:bg-green-600 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-green-600 focus:text-white"
            >
              Yes
            </button>
            <button
              type="button"
              className="px-10 py-2 text-base font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-lg hover:bg-red-500 hover:text-white focus:z-10 focus:ring-2 focus:shadow-xl focus:bg-red-500 focus:text-white"
              onClick={() => dispatch(setStudentState({ mode: 0 }))}
            >
              No
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="px-10 py-2 text-base font-medium text-gray-900 bg-transparent border border-gray-900 rounded-s-lg hover:bg-green-600 hover:text-white focus:z-10 focus:ring-2 focus:shadow-xl focus:bg-green-600 focus:text-white"
              onClick={() => dispatch(setStudentState({ mode: 1 }))}
            >
              Yes
            </button>
            <button
              type="button"
              className="shadow-xl px-10 py-2 text-base font-medium text-white bg-red-500 border border-gray-900 rounded-e-lg hover:bg-red-500 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-red-500 focus:text-white"
            >
              No
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default StudentBtn;
