import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Navbar = () => {
  const user = useSelector((state) => state.user);

  let role_id;
  switch (user.role_id) {
    case 1:
      role_id = "Author";
      break;
    case 2:
      role_id = "National Representative";
      break;
    case 3:
      role_id = "Board Member";
      break;
    case 4:
      role_id = "Review Chair";
      break;
    case 5:
      role_id = "Adminstrator";
      break;
    case 6:
      role_id = "Super Adminstrator";
      break;
    default:
      role_id = "Error#!!";
      break;
  }
  // console.log("UserData : ", user);
  const dispatch = useDispatch();
  return (
    <nav className="bg-white border-gray-200 shadow-xl w-full">
      <div className="max-w-full flex flex-wrap items-center justify-around mx-auto p-3 py-4">
        <a
          href="https://isabe.org/"
          target="_blank"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src="../../../../../assets/img/logo.png"
            className="h-10 sm:h-14"
            alt="ISABE Logo"
          />
          <span className="self-center text-base font-semibold whitespace-nowrap dark:text-white mt-4"></span>
        </a>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {user.full_name && user.auth ? (
            <>
              <div className="flex-col items-start justify-center me-2 hidden lg:flex">
                <span className="block text-sm ">
                  {user.full_name
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
                <span className="block text-sm  text-gray-500 truncate">
                  {role_id}
                </span>
              </div>

              <button
                type="button"
                className="flex text-sm rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 "
                aria-expanded="false"
                aria-controls="user-profile"
                data-dropdown-toggle="user-profile"
                data-dropdown-placement="bottom"
              >
                <span className="sr-only">Open user menu</span>

                <img
                  className="w-11 h-11"
                  src={`https://flagsapi.com/${user.country_id}/shiny/64.png`}
                  alt="user photo"
                />
              </button>

              <div
                className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow-lg"
                id="user-profile"
              >
                <div className="px-4 py-3">
                  <span className="block text-sm text-gray-900">
                    {user.full_name
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </span>
                  <span className="block text-sm  text-gray-500 truncate">
                    {user.email}
                  </span>
                  <span className="block text-sm  text-gray-500 truncate">
                    {role_id}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  {/* <li>
                    <a
                      href="https://panel.isabe.org/admin"
                      target="_blank"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Dashboard
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://panel.isabe.org/admin/profile"
                      target="_blank"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Profile
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://panel.isabe.org/admin"
                      target="_blank"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Dashboard
                    </a>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <></>
          )}
          <button
            data-collapse-toggle="navbar-user"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-user"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-user"
        >
          <ul className="text-sm lg:text-base flex flex-col font-medium gap-1 lg:gap-4 p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
            <li>
              <a
                href="https://panel.isabe.org/admin"
                target="_blank"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="https://www.isabe.org/?page_id=792"
                target="_blank"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"
              >
                Conference 2024
              </a>
            </li>
            <li>
              <a
                href="https://www.isabe.org/?page_id=1377"
                target="_blank"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"
              >
                Short Courses
              </a>
            </li>
            <li>
              {user.role_id === 5 ? (
                <a
                  href="https://docs.google.com/spreadsheets/d/1TXluNjt8LvaNmX_8CW8UJ9XVdUFUTW6tnVUrcVCx_F0/edit?usp=sharing"
                  target="_blank"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"
                >
                  Transactions
                </a>
              ) : (
                <Link
                  to="/myPurchase"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"
                >
                  My Purchase
                </Link>
              )}
            </li>
            <li>
              <a
                href="https://www.isabe.org/?page_id=520"
                target="_blank"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
