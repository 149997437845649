const ProductHeading = ({ text }) => {
  let category;

  switch (text) {
    case "ticket":
      category = "Conference Registration";
      break;
    case "course":
      category = "Short Courses";
      break;
    case "socialConference":
      category = "Social Events - Conference Attendees";
      break;
    case "socialAccompnying":
      category = "Social Events - Accompanying Person";
      break;
  }
  
  return (
    <div className="text-3xl font-semibold text-center py-5 mt-3 text-gray-900 w-full col-span-1 md:col-span-2">
      {category}
    </div>
  );
};

export default ProductHeading;
