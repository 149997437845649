import {createSlice} from '@reduxjs/toolkit';
import { userList } from '../data/userData';

const userSlice = createSlice({
    name: "users",
    initialState: userList,
    reducers: {
        setUser : (state, action)=>{
            Object.assign(state, action.payload);
        },
        setUserAuth : (state, action)=>{
            state.auth = action.payload.auth;
        }
    }
});

export const {setUser,setUserAuth} = userSlice.actions;
export default userSlice.reducer;