const NotLoggedIn = () => {
  return (
    <div className="flex flex-row flex-wrap gap-4 justify-around items-center p-2 sm:p-5 py-5 shadow-xl bg-white rounded-lg w-full">
      <span className="text-2xl font-medium text-center py-2 text-gray-900">
        Please Login!!
      </span>
      <a
        type="button"
        href="https://panel.isabe.org/admin/login"
        className="shadow-xl px-10 py-2 text-base font-medium text-white bg-green-600 border border-gray-900 rounded-lg hover:bg-green-600 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-green-600 focus:text-white"
      >
        Login
      </a>
    </div>
  );
};

export default NotLoggedIn;
