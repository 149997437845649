import { createSlice } from "@reduxjs/toolkit";

const allProductSlice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    addItems: (state, action) => {
      return action.payload;
    }
  },
});

export const { addItems } = allProductSlice.actions;
export default allProductSlice.reducer;
