import { createSlice } from "@reduxjs/toolkit";
import { productsList } from "../data/shoppingCartState";

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: productsList,
  reducers: {
    addAllItems: (state, action) => {
      return action.payload;
    },
    addItem: (state, action) => {
      action.payload.quantity = 1;
      return [...state, action.payload];
    },
    deleteItem: (state, action) => {
      const newState = [...state];
      if (newState.filter((prod) => prod.id === action.payload.id).length > 1) {
        newState.filter((prod) => prod.id === action.payload.id)[0].quantity -= 1;

        state =  [
          ...newState.filter((prod) => prod.id !== action.payload.id),
          ...newState.filter(
            (prod) => prod.id === action.payload.id && prod.quantity > 0
          ),
        ];
      } else {
        return state.filter((data) => data.id !== action.payload.id);
      }
    },
    increaseItem: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state[index].quantity++;
      }
      return state;
    },
    decreaseItem: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1 && state[index].quantity > 1) {
        state[index].quantity--;
      }
      return state;
    },
  },
});

export const { addAllItems, addItem, deleteItem, increaseItem, decreaseItem } =
  shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
