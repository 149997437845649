import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const PersonFrom = ({ no, id }) => {
  let title;
  const passportRequiredVisits = [6, 7, 8, 11, 12, 19, 20];
  const user = useSelector((state) => state.user);

  switch (id) {
    case 1:
      title = "Conference Attendee";
      break;
    case 2:
      title = "Accompanying Person";
      break;
    case 3:
      title = "Gas Turbine Performance Attendee";
      break;
    case 4:
      title = "Hydrogen for Aviation Attendee";
      break;
    case 5:
      title = "Aviation sustainability Attendee";
      break;
    case 6:
      title = "Airbus FAL 25th Conference Visitor";
      break;
    case 7:
      title = "Airbus FAL 27th Conference Visitor";
      break;
    case 8:
      title = "ONERA and ISAE Supaero Visitor";
      break;
    case 9:
      title = "Cite’ de l’Espace 25th Conference Visitor";
      break;
    case 10:
      title = "Visit of Toulouse Conference Visitor";
      break;
    case 11:
      title = "Airbus FAL 25th Accompanying Person Visitor";
      break;
    case 12:
      title = "Airbus FAL 27th Accompanying Person Visitor";
      break;
    case 13:
      title = "Cite’ de l’Espace 25th Accompanying Person Visitor";
      break;
    case 14:
      title = "Visit of Toulouse Accompanying Person Visitor";
      break;
    case 15:
      title = "Tour - Visit of Toulouse Visitor";
      break;
    case 16:
      title = "Tour - Historical city of Caracassonne Visitor";
      break;
    case 17:
      title = "Cite’ de l’Espace 27th Conference Visitor";
      break;
    case 18:
      title = "Cite’ de l’Espace 27th Accompanying Person Visitor";
      break;
    case 19:
      title = "ONERA FAUGA MAUZAC Center Conference Visitor";
      break;
    case 20:
      title = "ISAE-SUPAERO Engineering School Conference Visitor";
      break;
    default:
      title = "Attendee";
      break;
  }

  return (
    <div className="flex flex-col gap-2 items-center mt-5 mb-2">
      <span name="PersonFormProdID" className="hidden">
        {id}
      </span>
      <div
        name="PersonFormTitle"
        className="text-lg font-medium text-gray-900 p-0 text-center max-w-full px-8"
      >
        {title} {no}
      </div>
      <form class="p-4 max-w-md mx-auto shadow-xl bg-white border border-gray-200 rounded-xl h-auto sm:h-min">
        <label
          for="personName"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Name
        </label>
        <div class="flex mb-4">
          <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md">
            <svg
              class="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
          </span>
          <input
            type="text"
            id="personName"
            name="PersonFormName"
            class="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5"
            placeholder="Enter Name"
            required
          />
        </div>
        <label
          for="email-address-icon"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Email ID
        </label>
        <div class="relative mb-4">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <svg
              class="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 16"
            >
              <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
              <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
            </svg>
          </div>
          <input
            type="text"
            name="PersonFormEmail"
            id="email-address-icon"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
            placeholder="Enter Email ID"
            required
          />
        </div>
        <label
          for="mobile-address-icon"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Phone No.
        </label>
        <div class="relative mb-4">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <svg
              class="w-4 h-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z" />
            </svg>
          </div>
          <input
            type="text"
            id="mobile-address-icon"
            name="PersonFormPhoneNo"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
            placeholder="Phone No. with country code"
            required
          />
        </div>
        <label
          for="relation"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Relation / Association
        </label>
        <select
          id="relation"
          name="PersonFormRelation"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option value="self">Self</option>
          <option value="spouse">Spouse</option>
          <option value="parent">Parent</option>
          <option value="child">Child</option>
          <option value="sibling">Sibling</option>
          <option value="friend">Friend</option>
          <option value="colleague">Colleague</option>
          <option value="employee">Employee</option>
          {user.role_id === 5 ? (
            <option value="complementary">Complementary</option>
          ) : (
            <></>
          )}
          <option value="other">Other</option>
        </select>
      </form>
      {passportRequiredVisits.includes(id) ? (
        <div
          name="PersonFormTitle"
          className="text-base font-medium text-gray-900 p-2 text-center max-w-full px-2"
        >
          *Passport copy required for visit and should be mailed to{" "}
          <a className="text-blue-500" href="info@isabe.org">
            info@isabe.org
          </a>{" "}
          and{" "}
          <a className="text-blue-500" href="info@isabe.org">
            carole.perrot@airbus.com
          </a>{" "}
          after registration with subject <i>"{title}"</i>.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PersonFrom;
