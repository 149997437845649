import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import UserReducer from './reducer/UserReducer';
import ShoppingCartReducer from './reducer/ShoppingCartReducer';
import PersonFormReducer from './reducer/PersonFormReducer';
import AllProductsReducer from './reducer/AllProductsReducer';
import PrevioudCartReducer from './reducer/PrevioudCartReducer';
import StudentModeReducer from './reducer/StudentModeReducer';

const store = configureStore({
  reducer:{
    user: UserReducer,
    shoppingCart: ShoppingCartReducer,
    personForm: PersonFormReducer,
    products: AllProductsReducer,
    previousCart: PrevioudCartReducer,
    studentMode: StudentModeReducer
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);