import {createSlice} from '@reduxjs/toolkit';

const personFormSlice = createSlice({
    name: "personForm",
    initialState: {open: false, data:[]},
    reducers: {
        setFormState : (state, action)=>{
            state.open = action.payload.open;
            return state;
        }
    }
});

export const {setFormState} = personFormSlice.actions;
export default personFormSlice.reducer;