import { useSelector, useDispatch } from "react-redux";
import { setUser, setUserAuth } from "../reducer/UserReducer";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Cart from "./Cart";
import Product from "./Product";
import Footer from "./Footer";
import PersonFrom from "./PersonFrom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { addItems } from "../reducer/AllProductsReducer";
import ProductHeading from "./ProductHeading";
import { addAllItems } from "../reducer/ShoppingCartReducer";
import { updatePreviousCart } from "../reducer/PrevioudCartReducer";
import StudentBtn from "./StudentBtn";
import { setFormState } from "../reducer/PersonFormReducer";
import NotLoggedIn from "./NotLoggedIn";
import Modal from "./Modal";

const Home = () => {
  const user = useSelector((state) => state.user);
  const formState = useSelector((state) => state.personForm.open);
  const inCart = useSelector((state) => state.shoppingCart);
  const products = useSelector((state) => state.products);
  const studentMode = useSelector((state) => state.studentMode.mode);

  // console.log("CartData : ", inCart.filter(item => item.id === 2)[0].quantity);
  const dispatch = useDispatch();

  let { id, role_id, email, full_name, country_id, phone } = useParams();

  email = decodeURIComponent(email);
  full_name = decodeURIComponent(full_name).replace(/\+/g, " ");
  country_id = decodeURIComponent(country_id);
  phone = decodeURIComponent(phone).replace(/\+/g, " ");

  // console.log("Url Received ID : ", id);
  // console.log("Url Received Role ID : ", role_id);
  // console.log("Url Received Email ID : ", decodeURIComponent(email));
  // console.log(
  //   "Url Received Full Name : ",
  //   decodeURIComponent(full_name).replace(/\+/g, " ")
  // );
  // console.log("Url Received Country ID : ", decodeURIComponent(country_id));
  // console.log(
  //   "Url Received Phone : ",
  //   decodeURIComponent(phone).replace(/\+/g, " ")
  // );
  // let forms = [];

  // for (let i = 0; i < inCart.filter(item => item.id === 2).quantity; i++) {
  //   forms.push(<PersonFrom key={i} />);
  // }

  const productBycat = {};
  const [productElements, setProductElements] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/country/flag/${country_id}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(function (response) {
        console.log("Country : ", response.data.code);
        dispatch(
          setUser({
            id,
            role_id: parseInt(role_id),
            email,
            full_name,
            country_id: response.data.code,
            phone,
            auth: true,
          })
        );
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, [products]);

  useEffect(() => {
    products.forEach((product) => {
      if (!productBycat[product.category]) {
        productBycat[product.category] = [];
      }
      productBycat[product.category].push(product);
    });

    const productsDisplay = [];
    for (const category in productBycat) {
      productsDisplay.push(
        <>
          <ProductHeading text={category} />
          {productBycat[category]
            // .filter(
            //   (product) =>
            //     product.id !== 17 && product.id !== 18 && product.id !== 8 && product.id !== 4
            // )
            .map((product) => (
              <Product
                key={product.id}
                id={product.id}
                name={product.name}
                description={product.description}
                price={product.price}
                imageSrc={product.imageSrc}
                imageAlt={product.imageAlt}
                href={product.href}
                quantity={product.quantity}
                discount={product.discount}
                category={product.category}
                discountMsg={product.discountMsg}
                limited={product.limited}
                active={product.active}
                hide={product.hide}
              />
            ))}
        </>
      );
    }

    setProductElements(productsDisplay);

    console.log("Triggered Product : ", products);
    console.log("Elements : ", productElements);
  }, [products]);

  useEffect(() => {
    if (user.id !== null && user.role_id !== null) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/product/list/${user.id}/${user.role_id}/${studentMode}`,
          {
            user,
            auth: localStorage.getItem("AdminToken"),
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        )
        .then(function (response) {
          // handle success
          let prodList = response.data.products;

          // for (let i = 0; i < prodList.length; i++) {
          //   prodList[i].quantity = 1;
          // }

          console.log("Received Products List : ", prodList);
          dispatch(addItems(prodList));
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.status);
          if (error.response.status) {
            dispatch(setUserAuth({ auth: false }));
            toast.error(`Please Login Again!!`, {
              autoClose: 3000,
            });
          } else {
            toast.error(`Sorry, facing some issues with your request!!`, {
              autoClose: 3000,
            });
          }
        })
        .finally(function () {
          // always executed
        });
    }
  }, [user]);

  useEffect(() => {
    if (user.id !== null && user.role_id !== null) {
      console.log(studentMode);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/product/list/${user.id}/${user.role_id}/${studentMode}`,
          {
            user,
            auth: localStorage.getItem("AdminToken"),
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        )
        .then(function (response) {
          // handle success
          let prodList = response.data.products;

          // for (let i = 0; i < prodList.length; i++) {
          //   prodList[i].quantity = 1;
          // }

          console.log("Received Products List : ", prodList);
          dispatch(addItems(prodList));
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.status);
          if (error.response.status) {
            dispatch(setUserAuth({ auth: false }));
            toast.error(`Please Login Again!!`, {
              autoClose: 3000,
            });
          } else {
            toast.error(`Sorry, facing some issues with your request!!`, {
              autoClose: 3000,
            });
          }
        })
        .finally(function () {
          // always executed
        });
    }
  }, [studentMode]);

  return (
    <>
      <Navbar />
      <ToastContainer />
      {user.role_id === 5 && user.auth === false ? <Modal /> : <></>}
      <div className="flex flex-col lg:flex-row flex-wrap gap-5 gap-x-1 m-1 mt-5 mb-5 max-w-screen p-0 sm:px-2">
        <div className="max-w-screen flex flex-col gap-4 m-1 sm:m-5 items-center justify-center lg:w-3/5">
          {user.auth ? (
            <>
              {user.role_id === 5 ? <></> : <StudentBtn />}
              <div className="relative grid grid-cols-1 md:grid-cols-2 justify-items-center gap-2 gap-y-4 grid-flow-row p-2 sm:p-5 py-5 min-h-min flex-2 shadow-xl bg-white rounded-lg w-full">
                {formState &&
                products.length > 0 &&
                inCart.filter((item) => item.quantity >= 1)[0] ? (
                  <>
                    <button
                      className="group absolute transition-all top-4 left-5 hover:bg-indigo-700 hover:shadow-lg rounded-full p-2"
                      onClick={() => dispatch(setFormState({ open: false }))}
                    >
                      <svg
                        class="w-6 h-6 text-gray-80 group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 12h14M5 12l4-4m-4 4 4 4"
                        />
                      </svg>
                    </button>
                    {inCart
                      .filter((item) => item.quantity >= 1)
                      .map((e, i) =>
                        [...Array(e.quantity)].map((a, j) => (
                          <PersonFrom key={i + j} no={j + 1} id={e.id} />
                        ))
                      )}
                  </>
                ) : (
                  productElements
                )}
              </div>
            </>
          ) : (
            <NotLoggedIn />
          )}
        </div>
        <Cart />
      </div>
      <Footer />
    </>
  );
};

export default Home;
