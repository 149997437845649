import {createSlice} from '@reduxjs/toolkit';

const studentModeSlice = createSlice({
    name: "studentMode",
    initialState: {mode: 0},
    reducers: {
        setStudentState : (state, action)=>{
            // Object.assign(state, action.payload);
            return action.payload;
        }
    }
});

export const {setStudentState} = studentModeSlice.actions;
export default studentModeSlice.reducer;