import { useSelector, useDispatch } from "react-redux";
import {
  deleteItem,
  increaseItem,
  decreaseItem,
} from "../reducer/ShoppingCartReducer";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setFormState } from "../reducer/PersonFormReducer";
import { loadStripe } from "@stripe/stripe-js";
// import { products } from "../data/availableProducts";
import { addAllItems } from "../reducer/ShoppingCartReducer";
import { updatePreviousCart } from "../reducer/PrevioudCartReducer";
import { setUserAuth } from "../reducer/UserReducer";
import axios from "axios";
import DOMPurify from "dompurify";

const Cart = () => {
  const productsList = useSelector((state) => state.shoppingCart);
  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state.user);
  const previousCart = useSelector((state) => state.previousCart);
  const dispatch = useDispatch();
  const [sum, setSum] = useState(0.0);
  const formState = useSelector((state) => state.personForm.open);
  const studentMode = useSelector((state) => state.studentMode.mode);
  let role_name;

  switch (user.role_id) {
    case 1:
      role_name = "Author";
      break;
    case 2:
      role_name = "National Representative";
      break;
    case 3:
      role_name = "Board Member";
      break;
    case 4:
      role_name = "Review Chair";
      break;
    case 5:
      role_name = "Adminstrator";
      break;
    case 6:
      role_name = "Super Adminstrator";
      break;
    default:
      role_name = "Error#!!";
      break;
  }

  const getPersonFormData = () => {
    const id = Array.from(document.getElementsByName("PersonFormProdID")).map(
      (element) => element.innerHTML
    );
    const name = Array.from(document.getElementsByName("PersonFormName")).map(
      (element) => element.value
    );
    const email = Array.from(document.getElementsByName("PersonFormEmail")).map(
      (element) => element.value
    );
    const phone = Array.from(
      document.getElementsByName("PersonFormPhoneNo")
    ).map((element) => element.value);
    const relation = Array.from(
      document.getElementsByName("PersonFormRelation")
    ).map((element) => element.value);

    let full = true;

    id.forEach((item) => {
      if (item === "") {
        full = false;
      }
    });

    name.forEach((item) => {
      if (item === "") {
        full = false;
      }
    });

    email.forEach((item) => {
      if (item === "") {
        full = false;
      }
    });

    phone.forEach((item) => {
      if (item === "") {
        full = false;
      }
    });

    relation.forEach((item) => {
      if (item === "") {
        full = false;
      }
    });

    const peopleData = [];

    for (let i = 0; i < id.length; i++) {
      const data = {
        product: DOMPurify.sanitize(id[i]),
        name: DOMPurify.sanitize(name[i]),
        email: DOMPurify.sanitize(email[i]),
        phone: DOMPurify.sanitize(phone[i]),
        relation: DOMPurify.sanitize(relation[i]),
      };
      peopleData.push(data);
    }
    console.log(peopleData);

    return { peopleData, full };
  };

  const makePayment = async () => {
    const totalCartQuantity = productsList
      .map((prod) => prod.quantity)
      .reduce((acc, currentValue) => acc + currentValue, 0);

    const { peopleData, full } = getPersonFormData();

    if (sum > 30000) {
      toast.error(
        `Total cart amount should be less than $30000.\nIf required please purchase in parts.`
      );
    }

    if (totalCartQuantity > 100) {
      console.log("Quantity : ", totalCartQuantity);
      toast.error(
        `Combined quantity of cart products should be less than 100.\nIf required please purchase in parts.`
      );
    }

    if (!full) {
      toast.error(
        `Can't leave empty fields!!\nPlease fill all the required data.`
      );
    }

    if (sum < 30000 && totalCartQuantity < 100 && full) {
      toast.success(`Payment process initiated!\nPlease be patient.`);

      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );

      const body = {
        products: productsList,
        user: user,
        peopleData: peopleData,
        studentMode,
        auth: localStorage.getItem("AdminToken"),
      };

      const headers = {
        "Content-Type": "application/json",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/payment/checkout`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
          }
        );

        const session = await response.json();

        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          console.log(result.error);
          toast.error(
            `Sorry, Payment Request Failed!!\nPlease Try again later.`,
            {
              autoClose: 3000,
            }
          );
        }
      } catch (error) {
        console.log(error.message);
        toast.error(
          `Sorry, Payment Request Failed!!\nPlease Try again later.`,
          {
            autoClose: 3000,
          }
        );
      }
    }
  };

  useEffect(() => {
    const prices = productsList.map((product) => {
      console.log(
        product.price,
        " x ",
        product.quantity,
        " ",
        product.discount
      );
      console.log(
        "Normal : ",
        product.price * product.quantity * ((100 - product.discount) / 100)
      );
      console.log(
        "Rounded Off : ",
        parseFloat(
          (
            product.price *
            product.quantity *
            ((100 - product.discount) / 100)
          ).toFixed(2)
        )
      );

      return parseFloat(
        (
          product.price *
          product.quantity *
          ((100 - product.discount) / 100)
        ).toFixed(2)
      );
    });
    setSum(prices.reduce((acc, currentValue) => acc + currentValue, 0));

    if (!productsList.filter((item) => item.quantity >= 1)[0]) {
      dispatch(setFormState({ open: false }));
    }

    if (JSON.stringify(productsList) !== JSON.stringify(previousCart)) {
      if (user.id !== null && user.role_id !== null) {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/product/offers/${user.id}/${user.role_id}/${studentMode}`,
            {
              products: productsList,
              user,
              auth: localStorage.getItem("AdminToken"),
            }
          )
          .then(function (response) {
            // handle success
            let prodList = response.data.products;
            let notificationMsg = response.data.notificationMsg;

            notificationMsg.forEach((element) => {
              toast.error(`${element}`, {
                autoClose: 5000,
              });
            });

            console.log("Updated Cart State With Offers : ", prodList);
            dispatch(updatePreviousCart(prodList));
            dispatch(addAllItems(prodList));
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.status);
            if (error.response.status) {
              dispatch(setUserAuth({ auth: false }));
              toast.error(`Please Login Again!!`, {
                autoClose: 3000,
              });
            } else {
              toast.error(`Sorry, facing some issues with your request!!`, {
                autoClose: 3000,
              });
            }
          })
          .finally(function () {
            // always executed
          });
      }
    }
  }, [productsList]);

  useEffect(() => {
    // const prices = productsList.map(
    //   (product) =>
    //     product.price * product.quantity * ((100 - product.discount) / 100)
    // );
    // setSum(prices.reduce((acc, currentValue) => acc + currentValue, 0));

    // if (!productsList.filter((item) => item.quantity >= 1)[0]) {
    //   dispatch(setFormState({ open: false }));
    // }

    if (user.id !== null && user.role_id !== null) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/product/offers/${user.id}/${user.role_id}/${studentMode}`,
          {
            products: productsList,
            user,
            auth: localStorage.getItem("AdminToken"),
          }
        )
        .then(function (response) {
          // handle success
          let prodList = response.data.products;
          // document.getElementById("shoppingCartItemList").innerHTML = "";
          console.log("Updated Cart State With Offers : ", prodList);
          dispatch(updatePreviousCart(prodList));
          dispatch(addAllItems(prodList));
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.status);
          if (error.response.status) {
            dispatch(setUserAuth({ auth: false }));
            toast.error(`Please Login Again!!`, {
              autoClose: 3000,
            });
          } else {
            toast.error(`Sorry, facing some issues with your request!!`, {
              autoClose: 3000,
            });
          }
        })
        .finally(function () {
          // always executed
        });
    }
  }, [studentMode]);

  return (
    <div className="flex h-full flex-col bg-white shadow-xl flex-1 shrink-0 mt-5 lg:w-2/5 rounded-lg md:sticky md:top-5">
      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
        <div className="flex items-start justify-between">
          <div className="text-lg font-medium text-gray-900">Shopping Cart</div>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Close panel</span>
            </button>
          </div>
        </div>

        <div className="mt-8">
          <div className="flow-root">
            <ul
              className="my-6 divide-y divide-gray-200"
              id="shoppingCartItemList"
            >
              {productsList.length === 0 ? (
                <li className="flex py-6">
                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                    <img
                      src="https://png.pngtree.com/png-clipart/20190117/ourmid/pngtree-hand-painted-trolley-empty-cart-daily-supplies-png-image_441612.jpg"
                      alt="Empty Cart"
                      className="h-full w-full object-cover object-center"
                    />
                  </div>

                  <div className="ml-4 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <h3>
                          <a href="#">Empty Cart</a>
                        </h3>
                        <p className="ml-4">$0.00</p>
                      </div>
                      <p className="mt-1 text-sm text-gray-500"></p>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-base">
                      <p className="text-gray-500">Qty 0</p>

                      <div className="flex">
                        <button
                          type="button"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <></>
              )}
              {productsList.map((product, index) => (
                <li
                  key={index}
                  className="flex flex-col items-center gap-4 sm:gap-2 sm:flex-row py-6"
                >
                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>

                  <div className="ml-4 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <h3>
                          <a href={product.href}>
                            {product.name
                              .split(" ")
                              .slice(0, -1)
                              .join(" ")
                              .slice(0, 27) +
                              "..." +
                              product.name.split(" ").pop().slice(0, 7) +
                              ".."}
                          </a>
                        </h3>
                        {product.quantity ? (
                          <p className="ml-4">
                            $ {(product.price * product.quantity).toFixed(2)}
                          </p>
                        ) : (
                          <p className="ml-4">$ {product.price}</p>
                        )}
                      </div>
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p className="mt-1 text-sm text-gray-500">
                          {product.description.slice(0, 37) + "..."}
                        </p>
                        {product.discount ? (
                          <p className="ml-2 my-auto text-red-700">
                            -$
                            {(
                              Math.round(
                                product.quantity *
                                  product.price *
                                  product.discount
                              ) / 100
                            ).toFixed(2)}
                          </p>
                        ) : (
                          <p className="ml-2 my-auto text-red-700"> </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-base mt-1">
                      <p className="text-gray-500">
                        Qty{" "}
                        {product.quantity ? (
                          <>
                            <button
                              className="cursor-pointer rounded-l bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50 text-sm"
                              onClick={() =>
                                dispatch(decreaseItem({ id: product.id }))
                              }
                            >
                              -
                            </button>
                            {product.quantity}
                            <button
                              className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50 text-sm"
                              onClick={() =>
                                dispatch(increaseItem({ id: product.id }))
                              }
                            >
                              +
                            </button>
                          </>
                        ) : (
                          <>1</>
                        )}
                      </p>

                      <div className="flex">
                        <button
                          type="button"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          onClick={() => {
                            dispatch(deleteItem({ id: product.id }));
                            toast.success(
                              `Removed ${product.name} form the cart.`,
                              {
                                autoClose: 3000,
                              }
                            );
                            // if (
                            //   product.id === 1 &&
                            //   productsList
                            //     .map((product) => product.id)
                            //     .includes(2)
                            // ) {
                            //   dispatch(deleteItem({ id: 2 }));
                            //   toast.success(
                            //     `Removed ${products[1].name} form the cart.`,
                            //     {
                            //       autoClose: 3000,
                            //     }
                            //   );
                            // }
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
        <div className="flex justify-between text-base font-medium text-gray-900">
          <p>Subtotal</p>
          <p>$ {sum.toFixed(2)}</p>
        </div>
        {user.discount ? (
          <div className="mt-0.5 flex justify-between text-sm font-medium text-red-600">
            <p>{role_name} Discount</p>
            <p>-{user.discount}%</p>
          </div>
        ) : (
          <></>
        )}
        <div className="flex justify-between text-lg font-medium text-gray-900 mt-0.5">
          <p>Grand Total</p>
          <p>$ {((sum * (100 - user.discount)) / 100).toFixed(2)}</p>
        </div>
        <p className="mt-3 text-sm text-gray-500">
          Final Discounts and Taxes will be determined at the checkout.
        </p>
        <p className="mt-0.5 text-sm text-gray-500 text-justify">
          <b>Note:</b> Discounts are Individual basis. It maybe based upon ones
          role and purchase history. While some are restricted by product
          quantity.
        </p>
        <div className="mt-6">
          {productsList.filter((item) => item.quantity >= 1)[0] &&
          !formState ? (
            <button
              className="w-full flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              onClick={() => dispatch(setFormState({ open: true }))}
            >
              Next
            </button>
          ) : productsList.length === 0 ? (
            <button className="w-full flex items-center justify-center rounded-md border border-transparent bg-blue-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 cursor-not-allowed">
              Empty Cart
            </button>
          ) : (
            <button
              className="w-full flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              onClick={makePayment}
            >
              Checkout
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
