import { createSlice } from "@reduxjs/toolkit";
import { productsList } from "../data/shoppingCartState";

const previousCartSlice = createSlice({
  name: "previousCart",
  initialState: productsList,
  reducers: {
    updatePreviousCart: (state, action) => {
      return action.payload
    },
  },
});

export const { updatePreviousCart } = previousCartSlice.actions;
export default previousCartSlice.reducer;
