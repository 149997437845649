// src/analytics.js
import ReactGA from 'react-ga';

const trackingId = "G-CGDMPPV3TE"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
};
